<App params={ f7params }>

    <!--  &lt;!&ndash; Left panel with cover effect&ndash;&gt;-->
    <!--  <Panel left cover themeDark>-->
    <!--    <View>-->
    <!--      <Page>-->
    <!--        <Navbar title="Left Panel"/>-->
    <!--        <Block>Left panel content goes here</Block>-->
    <!--      </Page>-->
    <!--    </View>-->
    <!--  </Panel>-->


    <!--  &lt;!&ndash; Right panel with reveal effect&ndash;&gt;-->
    <!--  <Panel right reveal themeDark>-->
    <!--    <View>-->
    <!--      <Page>-->
    <!--        <Navbar title="Right Panel"/>-->
    <!--        <Block>Right panel content goes here</Block>-->
    <!--      </Page>-->
    <!--    </View>-->
    <!--  </Panel>-->


    <!-- Your main view, should have "view-main" class -->
    <View main class="safe-areas" url="/"/>


    <!--  &lt;!&ndash; Popup &ndash;&gt;-->
    <!--  <Popup id="my-popup">-->
    <!--    <View>-->
    <!--      <Page>-->
    <!--        <Navbar title="Popup">-->
    <!--          <NavRight>-->
    <!--            <Link popupClose>Close</Link>-->
    <!--          </NavRight>-->
    <!--        </Navbar>-->
    <!--        <Block>-->
    <!--          <p>Popup content goes here.</p>-->
    <!--        </Block>-->
    <!--      </Page>-->
    <!--    </View>-->
    <!--  </Popup>-->

    <LoginScreen id="my-login-screen" opened={isOpen}>
        <View>
            <Page loginScreen>
                <LoginScreenTitle>Login</LoginScreenTitle>
                <List form>
                    <ListInput
                            type="text"
                            name="username"
                            placeholder="Your username"
                            value={username}
                            onInput={(e) => username = e.target.value}
                    />
                    <ListInput
                            type="password"
                            name="password"
                            placeholder="Your password"
                            value={password}
                            onInput={(e) => password = e.target.value}
                    />
                </List>
                <List>
                    <ListButton title="Sign In" onClick={() => alertLoginData()}/>
                </List>
                <BlockFooter>
                    Some text about login information.<br/>Click "Sign In" to close Login Screen
                </BlockFooter>
            </Page>
        </View>
    </LoginScreen>
</App>
<script>
    import {onMount} from 'svelte';

    import {
        f7,
        f7ready,
        App,
        Panel,
        Views,
        View,
        Popup,
        Page,
        Navbar,
        Toolbar,
        NavRight,
        Link,
        Block,
        BlockTitle,
        LoginScreen,
        LoginScreenTitle,
        List,
        ListItem,
        ListInput,
        ListButton,
        BlockFooter
    } from 'framework7-svelte';


    import routes from '../js/routes';

    // Framework7 Parameters
    let f7params = {
        name: 'Wallet Report', // App name
        theme: 'auto', // Automatic theme detection


        // App routes
        routes: routes,
    };
    // Login screen demo data
    let username = '';
    let password = '';
    let isOpen = !((localStorage.getItem('login') + "") === '1');


    function alertLoginData() {
        if (username.length > 0 && password.length > 0 && username === password && username === 'admin') {
            isOpen = false;
        }
        localStorage.setItem("login", "1");
    }

    onMount(() => {
        f7ready(() => {

            // Call F7 APIs here
        });
    })
</script>