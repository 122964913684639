import {f7} from "framework7-svelte";

const WebServices = {
    base: () => {
        if (location.hostname === "localhost" || location.hostname === "127.0.0.1") {
            return 'http://localhost/kashancardapp/ResourcesHolder/core/PaymentRestApi/Class/';
        } else {
            return 'http://android.kashancard.digihelp.net/ResourcesHolder/core/PaymentRestApi/Class/';
        }
    },
    method: {
        'Report': {'name': 'Report.php?method=report'},
    },
    function: {
        LoadData: function (request, callback, error = null, param = {}) {
            const url = WebServices.base() + request;
            const resultCallback = (data) => {
                if (data.length === 1) data = data[0];
                if (data.hasOwnProperty('response')) data = data.response;
                if (typeof callback == "function") callback(data);
            };
            console.log(url);
            (async function (address, p = {}) {
                if (typeof f7 != "undefined") {
                    f7.request.post(url, p, function (data, xhr, status) {
                        if (xhr === 200) {
                            resultCallback(data);
                        } else if (typeof error == "function") error(data);
                    }, function (xhr, status) {
                        console.log('error xhr :' + JSON.stringify(xhr));
                        console.log('error status :' + JSON.stringify(status));
                        console.log('error arguments :' + JSON.stringify(arguments));
                        if (typeof error == "function") error({xhr, data: status});
                    }, 'json');
                } else {
                    const response = await fetch(address);
                    if (response.status === 200) {
                        const data = await response.json();
                        resultCallback(data);
                    } else {
                        if (typeof error == "function") error({xhr: response, data: response});
                    }
                }
            })(url, param);


        }, Report: function (users, r, callback, error) {
            WebServices.function.LoadData(WebServices.method.Report.name, callback, null, {users, r}, error);
        }, excel: function (listReport, subTransaction, failedTrance, callback,error) {
            WebServices.function.LoadData('excel', callback, null, {listReport, subTransaction, failedTrance}, error);
        }
    }
};

export default WebServices;