<Page name="home">
    <!-- Top Navbar -->
    <Navbar sliding={false} large>
        <NavTitle sliding>گزارش کیف پول</NavTitle>

        <NavTitleLarge>گزارش کیف پول</NavTitleLarge>
    </Navbar>

    <!-- Page content -->
    <Block strong>
        <Card>
            <CardHeader>

                <Row class="width-100">
                    <Col>
                        <List noHairlinesMd class="width-100">
                            <ListInput placeholder="کد کاربری" resizable value={text}
                                       onInput={(e)=>idInput(e.target.value)}
                                       label="کد کاربری" floatingLabel type="textarea"/>
                        </List>
                    </Col>
                    <Col>
                        <div class="list no-hairlines-md fBYekan">
                            <ul>
                                <li>
                                    <div class="item-content item-input">
                                        <div class="item-inner">
                                            <div class="item-input-wrap height-100">
                                                <input type="text" placeholder="از تاریخ"
                                                       readonly="readonly" id="from-time"/>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </Col>
                    <Col>
                        <div class="list no-hairlines-md fBYekan">
                            <ul>
                                <li>
                                    <div class="item-content item-input">
                                        <div class="item-inner">
                                            <div class="item-input-wrap height-100">
                                                <input type="text" placeholder="تا تاریخ" readonly="readonly"
                                                       id="to-time"/>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </Col>
                </Row>
            </CardHeader>
            <CardContent>
                <Row>
                    <Col>
                        <List>
                            <ListItem checkbox title="نمایش ریز تراکنش ها" name="demo-checkbox" checked
                                      onChange={(e)=>subTransaction = e.target.checked}/>
                        </List>
                    </Col>
                    <Col>
                        <List>
                            <ListItem checkbox title="نمایش ناموفق ها" name="demo-checkbox" checked
                                      onChange={(e)=>failedTrance = e.target.checked}/>
                        </List>
                    </Col>
                </Row>
                <hr/>
                {#if listReport.length===0}
                    <center class="fBYekan"><p class="fBYekan">موردی یافت نشد.</p></center>
                {:else}
                <!--                    {JSON.stringify(listReport)}-->
                    {#each listReport as agent}
                        <div class="card data-table fBYekan">
                            <table id="report-table">
                                <thead>
                                <tr>
                                    <th class="label-cell">گزارش تراکنش کیف پول</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr class="bg-color-gray text-color-white">
                                    <td class="text-align-right" colspan="4">کد : {agent.key}</td>
                                    <td class="text-align-right" colspan="4">کاربر : {agent.agent.name}</td>
                                    <td class="text-align-right" colspan="4">مانده : {agent.agent.balance}</td>
                                </tr>
                                {#if agent.receiver.length>0}
                                    <tr>
                                        <td colspan="12" class="bg-color-deeporange text-color-white text-align-center">
                                            دریافت ها
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="label-cell text-align-center" colspan="2">نوع تراکنش</td>
                                        <td class="label-cell text-align-center" colspan="2">تاریخ</td>
                                        <td class="label-cell text-align-center" colspan="2">گیرنده/فرستنده</td>
                                        <td class="label-cell text-align-center" colspan="2">مبلغ</td>
                                        <td class="label-cell text-align-center" colspan="2">وضعیت</td>
                                        <td class="label-cell text-align-center" colspan="2">نوع کیف پول</td>
                                    </tr>
                                    {#each agent.receiver as s}
                                        <tr class='text-color-{s.status==="TRUE"?"green":"red"} {subTransaction?"":"display-none"} {s.status!=="TRUE"?(failedTrance?"":"display-none"):""}'>
                                            <td class="label-cell text-align-center" colspan="2">دریافت</td>
                                            <td class="label-cell text-align-center" colspan="2">{s.time}</td>
                                            <td class="label-cell text-align-center" colspan="2">
                                                {s.client}-{s.mobile}-{s.meli}
                                            </td>
                                            <td class="label-cell text-align-center" colspan="2">{s.price}</td>
                                            <td class="label-cell text-align-center" colspan="2">{s.message}</td>
                                            <td class="label-cell text-align-center" colspan="2">{s.group}</td>
                                        </tr>
                                    {/each}
                                    {#if agent.total.receive.length > 0}
                                        <tr>
                                            <td colspan="12">
                                                <table class="width-100">
                                                    <tr class="">
                                                        <th class="text-align-right" colspan="1">کد گروه</th>
                                                        <th class="text-align-right" colspan="1">نام گروه</th>
                                                        <th class="text-align-right" colspan="1">تعداد</th>
                                                        <th class="text-align-right" colspan="1">جمع کل</th>
                                                    </tr>
                                                    {#each agent.total.receive as t}
                                                        <tr>
                                                            <th class="text-align-right" colspan="1">{t.gid}</th>
                                                            <th class="text-align-right" colspan="1">{t.group}</th>
                                                            <th class="text-align-right" colspan="1">{t.count}</th>
                                                            <th class="text-align-right" colspan="1">{t.price}</th>
                                                        </tr>
                                                    {/each}
                                                </table>
                                            </td>
                                        </tr>
                                    {/if}
                                {/if}
                                {#if agent.send.length>0}
                                    <tr>
                                        <td colspan="12">
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="12" class="bg-color-deeporange text-color-white text-align-center">
                                            ارسال ها
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="label-cell text-align-center" colspan="2">نوع تراکنش</td>
                                        <td class="label-cell text-align-center" colspan="2">تاریخ</td>
                                        <td class="label-cell text-align-center" colspan="2">گیرنده/فرستنده</td>
                                        <td class="label-cell text-align-center" colspan="2">مبلغ</td>
                                        <td class="label-cell text-align-center" colspan="2">وضعیت</td>
                                        <td class="label-cell text-align-center" colspan="2">نوع کیف پول</td>
                                    </tr>
                                    {#each agent.send as s}
                                        <tr class='text-color-{s.status==="TRUE"?"green":"red"} {subTransaction?"":"display-none"} {s.status!=="TRUE"?(failedTrance?"":"display-none"):""}'>
                                            <td class="label-cell text-align-center" colspan="2">ارسال</td>
                                            <td class="label-cell text-align-center" colspan="2">{s.time}</td>
                                            <td class="label-cell text-align-center" colspan="2">
                                                {s.client}-{s.mobile}-{s.meli}
                                            </td>
                                            <td class="label-cell text-align-center" colspan="2">{s.price}</td>
                                            <td class="label-cell text-align-center" colspan="2">{s.message}</td>
                                            <td class="label-cell text-align-center" colspan="2">{s.group}</td>
                                        </tr>
                                    {/each}
                                    {#if agent.total.send.length > 0}
                                        <tr>
                                            <td colspan="12">
                                                <table class="width-100">
                                                    <tr class="">
                                                        <th class="text-align-right" colspan="1">کد گروه</th>
                                                        <th class="text-align-right" colspan="1">نام گروه</th>
                                                        <th class="text-align-right" colspan="1">تعداد</th>
                                                        <th class="text-align-right" colspan="1">جمع کل</th>
                                                    </tr>
                                                    {#each agent.total.send as t}
                                                        <tr>
                                                            <th class="text-align-right" colspan="1">{t.gid}</th>
                                                            <th class="text-align-right" colspan="1">{t.group}</th>
                                                            <th class="text-align-right" colspan="1">{t.count}</th>
                                                            <th class="text-align-right" colspan="1">{t.price}</th>
                                                        </tr>
                                                    {/each}
                                                </table>
                                            </td>
                                        </tr>
                                    {/if}
                                {/if}
                                </tbody>
                            </table>
                        </div>
                    {/each}
                {/if}
            </CardContent>
            <CardFooter>
                <Row class="width-100">
                    <Col width="33"/>
                    <Col width="33">
                        <Segmented>
                            {#if listReport.length>0}
                            <!--on:click={()=>download_table_as_csv("report-table",)}/>-->
                                <Button raised outline text="دانلود"
                                        on:click={()=>download_table_as_csv2(listReport,subTransaction,failedTrance)}/>
                            {/if}
                            <Button raised outline text="جستوجو" on:click={()=>Search()}/>
                        </Segmented>
                    </Col>
                    <Col width="33"/>
                </Row>
            </CardFooter>
        </Card>
    </Block>
</Page>
<style>
    :root {
        --f7-calendar-today-bg-color: #acacac;
    }
</style>
<script>
    let listReport = [], subTransaction = true, failedTrance = true;
    let JSON_TO_SEND = {listReport, subTransaction, failedTrance};
    const r = {
        from: {
            y: -1, m: -1, d: -1, hh: -1, mm: -1, ss: -1
        },
        to: {
            y: -1, m: -1, d: -1, hh: -1, mm: -1, ss: -1
        }
        // to: {y: 2020, m: 4, d: 12, hh: 1, mm: 54, ss: 0}
    };
    // import {} from 'framework7-svelte';
    import {onMount} from 'svelte';
    import {f7ready, f7, Segmented} from 'framework7-svelte';
    import {ListInput} from 'framework7-svelte';
    import {CardContent, CardFooter, CardHeader, Card} from 'framework7-svelte';
    import {Page, Navbar, NavLeft, NavTitle, NavTitleLarge} from 'framework7-svelte';
    import {NavRight, Link, Toolbar, Block, BlockTitle} from 'framework7-svelte';
    import {List, ListItem, Row, Col, Button} from 'framework7-svelte';
    import WebServices from '../js/WebServices';
    import {gregorian_to_jalali, jalali_to_gregorian} from '../js/jdf';


    let url = WebServices.base() + "reportExcel.php";

    function download_table_as_csv2(listReport, subTransaction, failedTrance) {
        JSON_TO_SEND = {listReport, subTransaction, failedTrance};

        const data = [];

        if (listReport.length > 0) {
            for (let i = 0; i < listReport.length; i += 1) {
                const row = listReport[i];
                data.push({"a": 'کد کاربری', 'b': 'کاربری', 'c': 'مانده'});
                data.push({"a": row['key'], 'b': row['agent']['name'], 'c': row['agent']['balance']});

                if (subTransaction) {
                    const receiver = row.receiver;
                    if (receiver.length > 0) {
                        data.push({
                            'a': '',
                            'b': '',
                            'c': 'دریافت',
                            'd': '',
                            'e': '',
                            'f': ''
                        });

                        data.push({
                            'a': 'نوع تراکنش',
                            'b': 'تاریخ',
                            'c': 'مشخصات گیرنده/فرستنده',
                            'd': 'مبلغ',
                            'e': 'وضعیت',
                            'f': 'نوع کیف پول'
                        });
                        data.push({});

                        for (let x = 0; x < receiver.length; x += 1) {
                            const tranc = receiver[x];

                            if (tranc.status === 'FALSE' && failedTrance) {
                                data.push({
                                    'a': tranc['mode'],
                                    'b': tranc['time'],
                                    'c': `${tranc['mobile']}-${tranc['meli']}-${tranc['client']}`,
                                    'd': tranc['price'],
                                    'e': tranc['message'],
                                    'f': tranc['group']
                                });
                            }
                        }
                    }


                    data.push({});
                    const total = row.total;

                    if (total.receive.length > 0) {
                        data.push({
                            'a': '',
                            'b': '',
                            'c': ' کل دریافت',
                            'd': '',
                            'e': '',
                            'f': ''
                        });

                        data.push({
                            'a': 'کد گروه',
                            'b': 'نام گروه',
                            'c': '',
                            'd': 'تعداد',
                            'e': '',
                            'f': 'جمع کل',
                        });

                        for (let m = 0; m < total.receive.length; m += 1) {
                            let h = total.receive[m];
                            data.push({
                                'a': h.gid,
                                'b': h.group,
                                'c': '',
                                'd': h.count,
                                'e': '',
                                'f': h.price,
                            });
                        }
                    }

                    data.push({}, {});


                    const send = row.send;
                    if (send.length > 0) {
                        data.push({
                            'a': '',
                            'b': '',
                            'c': 'ارسال',
                            'd': '',
                            'e': '',
                            'f': ''
                        });

                        data.push({
                            'a': 'نوع تراکنش',
                            'b': 'تاریخ',
                            'c': 'مشخصات گیرنده/فرستنده',
                            'd': 'مبلغ',
                            'e': 'وضعیت',
                            'f': 'نوع کیف پول'
                        });
                        data.push({});

                        for (let x = 0; x < send.length; x += 1) {
                            const tranc = send[x];

                            if ((tranc.status === 'TRUE') || (tranc.status === 'FALSE' && failedTrance)) {
                                data.push({
                                    'a': tranc['mode'],
                                    'b': tranc['time'],
                                    'c': `${tranc['mobile']}-${tranc['meli']}-${tranc['client']}`,
                                    'd': tranc['price'],
                                    'e': tranc['message'],
                                    'f': tranc['group']
                                });
                            }
                        }
                    }

                    if (total.send.length > 0) {
                        data.push({
                            'a': '',
                            'b': '',
                            'c': 'کل ارسالی',
                            'd': '',
                            'e': '',
                            'f': ''
                        });

                        data.push({
                            'a': 'کد گروه',
                            'b': 'نام گروه',
                            'c': '',
                            'd': 'تعداد',
                            'e': '',
                            'f': 'جمع کل'
                        });

                        for (let m = 0; m < total.send.length; m += 1) {
                            let h = total.send[m];
                            data.push({
                                'a': h.gid,
                                'b': h.group,
                                'c': '',
                                'd': h.count,
                                'e': '',
                                'f': h.price,
                            });
                        }
                    }

                    data.push({}, {});


                    data.push({}, {}, {});
                }
            }
        }

        json_as_csv(data);
    }

    function download_table_as_csv(table_id) {
        // Select rows from table_id
        const rows = document.querySelectorAll('table#' + table_id + ' tr');
        // Construct csv
        const csv = [];
        for (let i = 0; i < rows.length; i++) {
            const row = [], cols = rows[i].querySelectorAll('td, th');
            for (let j = 0; j < cols.length; j++) {
                // Clean innertext to remove multiple spaces and jumpline (break csv)
                let data = cols[j].innerText.replace(/(\r\n|\n|\r)/gm, '').replace(/(\s\s)/gm, ' ');
                // Escape double-quote with double-double-quote (see https://stackoverflow.com/questions/17808511/properly-escape-a-double-quote-in-csv)
                data = data.replace(/"/g, '""');
                // Push escaped string
                row.push('"' + data + '",');
            }
            csv.push(row);
        }
        const csv_string = csv.join('\n');
        // Download it
        const filename = 'export_' + table_id + '_' + new Date().toLocaleDateString() + '.csv';
        const link = document.createElement('a');
        link.style.display = 'none';
        link.setAttribute('target', '_blank');
        link.setAttribute('class', 'external');
        link.setAttribute('href', 'data:text/csv;charset=utf-8,%EF%BB%BF' + encodeURI(csv_string));
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    const item = localStorage.getItem("id");
    let text = "";
    if (item !== null && item !== undefined && item.length > 0) {

        try {
            let json = JSON.parse(item);
            for (let i = 0; i < json.length; i += 1) {
                text += json[i];
                if ((i + 1) < json.length) text += "\n";
            }
            text = text;
        } catch (e) {

        }
    }

    function idInput(text) {
        try {
            if (text.length > 0) {
                let e = text.split("\n");
                if (e.length > 0) {
                    const data = [];
                    e.forEach(function (e) {
                        if (e.length > 0) data.push(e);
                    });
                    localStorage.setItem('id', JSON.stringify(data));
                }
            } else {
                localStorage.setItem("id", "[]");
            }
        } catch (e) {

        }
        text = text;
    }

    let is_send = true;

    function Search() {
        if (r.to.y === -1 && r.from.y === -1) {
            alert('یک تاریخ وارد کنید.');
        } else {
            const users = localStorage.getItem("id");
            if (is_send) {
                if (users !== null && users !== undefined && users.length > 0) {
                    const user = JSON.parse(users);
                    if (user.length > 0) {
                        is_send = false;
                        WebServices.function.Report(user, r, function (data) {
                            console.log(r);
                            is_send = true;
                            listReport = [];
                            if (typeof data == "object" && data.hasOwnProperty('agent')) {
                                listReport.push(data);
                                listReport == listReport;
                            } else if (data.hasOwnProperty('length') && data.length > 0) {
                                listReport = data;
                            } else {
                                listReport = [];
                            }
                            console.log(listReport);
                        }, () => is_send = false);
                    } else {
                        alert('کد/کدهای کاربری را وارد کنید.');
                    }
                } else {
                    alert('کد/کدهای کاربری را وارد کنید.');
                }
            } else {
                alert('صبر کنید.');
            }
        }
    }

    // Search();
    onMount(() => {
        f7ready((app) => {
            let from = app.calendar.create({
                inputEl: '#from-time',
                timePicker: true,
                dateFormat: {day: 'numeric', month: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric'},
                rangePicker: false,
                timePickerPlaceholder: 'ساعت ',
                locale: 'fa'
            }), to = app.calendar.create({
                inputEl: '#to-time',
                timePicker: true,
                dateFormat: {day: 'numeric', month: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric'},
                rangePicker: false,
                timePickerPlaceholder: 'ساعت ',
                locale: 'fa'
            });

            from.on('change', function (e, v) {
                let c = Date.parse(from.getValue().toString());
                c = new Date(c);

                r.from.m = c.getMonth() + 1;
                r.from.d = c.getDate();
                r.from.y = c.getFullYear();

                r.from.hh = c.getHours();
                r.from.mm = c.getMinutes();
                r.from.ss = c.getSeconds();

            });

            to.on('change', function () {
                let c = Date.parse(to.getValue().toString());
                c = new Date(c);

                r.to.m = c.getMonth() + 1;
                r.to.d = c.getDate();
                r.to.y = c.getFullYear();

                r.to.hh = c.getHours();
                r.to.mm = c.getMinutes();
                r.to.ss = c.getSeconds();
            });
        });
    });


    function GenerateFile(filename, csv_string) {
        const link = document.createElement('a');
        link.style.display = 'none';
        link.setAttribute('target', '_blank');
        link.setAttribute('class', 'external');
        link.setAttribute('href', 'data:text/csv;charset=utf-8,%EF%BB%BF' + encodeURI(csv_string));
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }


    function json_as_csv(json, name = 'sample') {
        const csv = [], add = (text) => {
            // Clean innertext to remove multiple spaces and jumpline (break csv)
            let data = (JSON.stringify(text)).replace(/(\r\n|\n|\r)/gm, '').replace(/(\s\s)/gm, ' ');
            data = data.toString();
            // Escape double-quote with double-double-quote (see https://stackoverflow.com/questions/17808511/properly-escape-a-double-quote-in-csv)
            data = data.replace(/"/g, '');
            return `"'${data}'"`;
        };

        for (let i = 0; i < json.length; i++) {
            const row = [];
            if (json[i].length !== undefined) {
                for (let j = 0; j < json[i].length; j++) {
                    // Push escaped string
                    row.push(add(json[i][j]));
                }
            } else {
                const keys = Object.keys(json[i]);
                if (keys.length > 0) {
                    for (let j = 0; j < keys.length; j++) {
                        // Push escaped string
                        row.push(add(json[i][keys[j]]));
                    }
                }
            }
            csv.push(row);
        }
        const csv_string = csv.join('\n');
        // Download it
        const filename = `export_${name}_` + new Date().toLocaleDateString() + '.csv';
        GenerateFile(filename, csv_string);
    }
</script>